<template>
	<div class="packaging-body">
		<div class="add" @click="open_role(1)">+ 新增角色</div>
		
		<Table :columns="columns" :data="data">
			<template slot="id" slot-scope="{row,index}">
				{{ index+1 }}
			</template>
			<template slot="operation" slot-scope="{row}">
				<div class="operation">
					<div @click="open_del_role(1,row)">菜单权限</div>
					<div style="color: #E8EAEC;">|</div>
					<div @click="open_role(2,row)">编辑</div>
					<div style="color: #E8EAEC;">|</div>
					<div @click="open_del_role(2,row)">删除</div>
				</div>
			</template>
		</Table>
		
		<div class="ser-page">
			<Page class-name="page-r" :total="serForm.total" :page-size="serForm.pageSize" :page-size-opts="[15,30,50]"
				@on-change="changePage" @on-page-size-change="changePageSize" show-sizer show-total />
		</div>
		
		<!-- 新增、编辑角色 -->
		<Modal v-model="role" :title="(formValidate.id?'编辑':'新增')+'角色'" :footer-hide='true'>
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="90">
				<FormItem label="角色名称:" prop="name">
					<Input v-model="formValidate.name"></Input>
				</FormItem>
				<FormItem label="说明:" prop="explain">
					<Input v-model="formValidate.explain"></Input>
				</FormItem>
				<FormItem label="排序:" prop="sort">
					<Input v-model="formValidate.sort" style="width: 80px;"></Input>
				</FormItem>
			</Form>
			<div class="button">
				<Button @click="role = false">取消</Button>
				<Button class="determine" @click="edit_role()">确定</Button>
			</div>
		</Modal>
		
		<!-- 删除角色 -->
		<Modal v-model="open_del" @on-ok="del_role" width="400">
			<div>
				<div style="font-size: 18px;">提示：</div>
				<div>确定要删除 [ {{del_name}} ] 该角色吗？</div>
			</div>
		</Modal>
		
		<!-- 菜单权限 -->
		<Modal v-model="open_menu" :title="'分配'+del_name+'的菜单权限'" :footer-hide="true">
			<div style="height: 300px;overflow-y: auto;">
				<Tree ref="tree" :data="menu_data" show-checkbox></Tree>
			</div>
			<div class="button">
				<Button @click="open_menu = false">取消</Button>
				<Button class="determine" @click="savePower">保存权限</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				columns:[
					{title:'ID' , slot:'id' , maxWidth:80},
					{title:'角色名称' , key:'role_name'},
					{title:'说明' , key:'role_explain'},
					{title:'创建时间' , key:'creation_time'},
					{title:'更新时间' , key:'update_time'},
					{title:'排序' , key:'role_sort'},
					{title:'操作' , slot:'operation' , fixed:'right'}
				],
				data:[],
				role:false,
				formValidate:{
					id:null,
					name:null,
					explain:null,
					sort:99,
				},
				ruleValidate:{
					name: [
						{ required: true, message: '请填写角色名称', trigger: 'blur' }
					],
				},
				open_del:false,
				del_name:null,
				del_id:null,
				open_menu:false,
				menu_data:[],
				serForm: {
					page: 1,
					pageSize: 15,
					total: 0
				},
			}
		},
		created() {
			this.init();
		},
		methods:{
			init(){
				var _this = this
				this.requestApi('/adm/get_role_manage',{
					serForm:this.serForm
				}).then(function(res){
					if(res.status == 200){
						_this.data = res.data.data
						_this.serForm.total = res.data.total
					}else {
						_this.alertErr('获取数据失败')
					}
				})
			},
			open_role(e,row){
				if(e == 1){
					this.formValidate.id = null
					this.formValidate.name = null
					this.formValidate.explain = null
					this.formValidate.sort = 99
				}else {
					this.formValidate.id = row.id
					this.formValidate.name = row.role_name
					this.formValidate.explain = row.role_explain
					this.formValidate.sort = row.role_sort
				}
				this.role = true
			},
			edit_role(){
				for(let i in this.data){
					if(this.formValidate.id != this.data[i].id && this.data[i].role_name == this.formValidate.name) return this.alertErr('该角色已存在在！')
				}
				
				if(!this.formValidate.name) return this.alertErr('请完善信息后再次提交！')
				var _this = this 
				this.requestApi('/adm/edit_role',{
					data:this.formValidate
				}).then(function(res) {
					if(res.status == 200){
						_this.alertSucc(res.msg)
						_this.role = false
						_this.init()
					}else {
						_this.alertErr(res.msg)
					}
				})
			},
			open_del_role(e,row){
				if(e == 1){
					var _this = this 
					this.requestApi("/adm/get_cloud_menu",{
						id:row.id
					}).then(function(res) {
						if(res.status == 200 ){
							_this.menu_data = res.data
						}else {
							_this.alertErr('获取云菜单失败')
						}
					})
					this.open_menu = true
				}else {
					this.open_del = true
				}
				this.del_name = row.role_name
				this.del_id = row.id
			},
			del_role(){
				var _this = this 
				this.requestApi('/adm/del_role',{
					id:this.del_id
				}).then(function(res) {
					if(res.status == 200 ){
						_this.alertSucc(res.msg)
						_this.init()
					}else {
						_this.alertErr(res.msg)
					}
				})
			},
			savePower(){
				var data = this.$refs.tree.getCheckedAndIndeterminateNodes();
				var powerId = this.set_power(data);
				if(!powerId) return this.alertErr('请选择权限！')
				var _this = this 
				this.requestApi('/adm/add_menu',{
					data:powerId,
					id:this.del_id
				}).then(function(res) {
					if(res.status == 200 ){
						_this.alertSucc(res.msg)
						_this.open_menu = false
						_this.init()
					}else {
						_this.alertErr(res.msg)
					}
				})
			},
			set_power(data){
				var power = ''
				for (let i=0;i<data.length;i++){
					power += power==''?data[i].id:','+data[i].id
				}
				return power
			},
			serInit() {
				this.serForm.page = 1;
				this.init();
			},
			changePage(page) {
				this.serForm.page = page;
				this.init();
			},
			changePageSize(size) {
				this.serForm.page = 1;
				this.serForm.pageSize = size;
				this.init();
			},
		}
	}
</script>

<style scoped>
	.packaging-body {
		padding: 16px;
		background: #fff;
	}
	.add {
		border-radius: 5px;
		width: 100px;
		height: 32px;
		background-color: #1890FF;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
	.add:hover {
		background-color: #57A3F3;
	}
	.operation {
		display: flex;
		justify-content: space-around;
		color: #1890FF;
	}
	.button {
		margin-top: 20px;
		margin: 0 -16px;
		border-top: 1px solid #e5e5e5;
		padding: 16px 18px 0;
		text-align: right;
	}
	.determine {
		background-color: #1890FF;
		color: #fff;
		margin-left: 10px;
	}
</style>
